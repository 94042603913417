import React from "react"
import PropTypes from "prop-types"
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DefaultBackground from "../../images/default-bg.jpg"

const FancyHeader = ({ title, subTitle, background, tall, inverse }) => {
  const style = {
    background: `url(${DefaultBackground})`,
  }

  if (background) {
    style.background = `url(${background})`
  }

  const className = tall ? "fancy-header tall" : "fancy-header"

  const titleBlock = (
    <>
      <h1>{title}</h1>
      <h3>{subTitle}</h3>
    </>
  )

  return (
    <Jumbotron fluid style={style} className={className}>
      <Container>
        <Row>
          <Col xs={12}>{titleBlock}</Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}

FancyHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  tall: PropTypes.bool,
  inverse: PropTypes.bool,
}

export default FancyHeader
