import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"
import Validation from "../../helpers/Validation"
import { PhoneNumber } from "../../helpers/Parse"

const ContactForm = ({ onSubmit, onError, sending, sent }) => {
  const [invalidName, setInvalidName] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const onInputChange = (key) => (event) => {
    const value = event.target.value

    switch (key) {
      case "name":
        setInvalidName(!Validation("smallStrLength")(value))
        setName(value)
        break
      case "email":
        setInvalidEmail(!Validation("email")(value))
        setEmail(value)
        break
      case "phone":
        setInvalidPhone(!Validation("phone")(PhoneNumber(value)))
        setPhone(value)
        break
      case "message":
        setInvalidMessage(!Validation("mediumStrLength")(value))
        setMessage(value)
        break
      default:
        console.error(`Unsupported key ${key}`)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (invalidName || invalidEmail || invalidPhone || invalidMessage) {
      onError("Please finish the form!")
      return
    }

    onSubmit(name, email, phone, message)
  }

  var action = "Send the Message"

  if (sending) {
    action = (
      <>
        Sending... <FontAwesomeIcon icon={faPaperPlane} />
      </>
    )
  }

  if (sent) {
    action = (
      <>
        Sent <FontAwesomeIcon icon={faCheck} />
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="salesNameText">
        <Form.Label>Full Name or Organization</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your Name or Company"
          onChange={onInputChange("name")}
          isInvalid={invalidName}
          value={name}
        />
      </Form.Group>
      <Form.Row>
        <Col>
          <Form.Group controlId="salesEmailText">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="email@website.com"
              onChange={onInputChange("email")}
              isInvalid={invalidEmail}
              value={email}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="salesPhoneText">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="phone"
              placeholder="555-555-5432"
              onChange={onInputChange("phone")}
              isInvalid={invalidPhone}
              value={phone}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="salesMessageText">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          onChange={onInputChange("message")}
          isInvalid={invalidMessage}
          value={message}
        />
      </Form.Group>
      <Button
        variant={sent ? "outline-success" : "outline-light"}
        type="submit"
        disabled={sending}
      >
        {action}
      </Button>
    </Form>
  )
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
}

export default ContactForm
