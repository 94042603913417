const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const usernameRegex = /^[a-z0-9]+(?:[_-][a-z0-9]+)*$/
const phoneRegex = /^(\+?)[0-9]+(\x|\ext)?[0-9]+$/
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/

const Validation = (type) => (value) => {
  switch (type) {
    case "email":
      return emailRegex.test(String(value).toLowerCase())
    case "username":
      return usernameRegex.test(String(value))
    case "phone":
      return phoneRegex.test(String(value).toLowerCase())
    case "password":
      return passwordRegex.test(String(value))
    case "smallStrLength":
      return 0 < value.length && value.length <= 175
    case "mediumStrLength":
      return 0 < value.length && value.length <= 525
    case "largeStrLength":
      return 0 < value.length && value.length <= 1050
    default:
      throw new Error(`Unsupported validation type ${type}`)
  }
}

export default Validation
