import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import ContactForm from "../components/forms/ContactForm"
import FancyHeader from "../components/blocks/FancyHeader"
import * as EmailerActions from "../actions/EmailerActions"

const ContactPage = ({ data, actions, state }) => {
  const { contentfulPage } = data
  const { SendSalesEmail, SendSalesError } = actions
  const { salesSent, isSendingSales } = state

  return (
    <Page
      zeroTop
      {...contentfulPage}
      seoTitle="Rallista - Contact Our Sales Team"
      seoDescription="Contact the Rallista sales team. Get quotes for large events, custom event features, branded content such as drive packs and more."
    >
      <FancyHeader
        title={"Contact Rallista"}
        subTitle={"Reach out to our Sales Team"}
      />
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <ContactForm
              onSubmit={SendSalesEmail}
              onError={SendSalesError}
              sending={isSendingSales}
              sent={salesSent}
            />
            <br />
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    SendSalesEmail: PropTypes.func.isRequired,
    SendSalesError: PropTypes.func.isRequired,
  }),
  state: PropTypes.shape({
    salesSent: PropTypes.bool.isRequired,
    isSendingSales: PropTypes.bool.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  state: state.emailer,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(EmailerActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)

export const pageQuery = graphql`
  query ContactPageQuery {
    contentfulPage(rallistaId: { eq: "contact" }) {
      contentful_id
      seoTitle
      rallistaId
      seoDescription
    }
  }
`
